import React from "react"
import "../styles.css"

const NotFoundPage = () => {

  return (
    <div style={{width: "380px", margin: "20vh auto"}} className="container text-center mt-64">
      <h1>We can't find the page your looking for. You can try and head back to the <a href="https://dudos.co.uk">home page</a> </h1>
    </div>
  )
}

export default NotFoundPage;
